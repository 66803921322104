import { Action } from '@ngrx/store';

import { Pos } from '../../models';

export enum ActionTypes {
  LOAD_REQUEST = '[Pos] Load Request',
  LOAD_FAILURE = '[Pos] Load Failure',
  LOAD_SUCCESS = '[Pos] Load Success',

  CREATE_REQUEST = '[Pos] Create Request',
  CREATE_FAILURE = '[Pos] Create Failure',
  CREATE_SUCCESS = '[Pos] Create Success',

  DELETE_REQUEST = '[Pos] Delete Request',
  DELETE_FAILURE = '[Pos] Delete Failure',
  DELETE_SUCCESS = '[Pos] Delete Success',

  UPDATE_REQUEST = '[Pos] Update Request',
  UPDATE_FAILURE = '[Pos] Update Failure',
  UPDATE_SUCCESS = '[Pos] Update Success',

  RESET_STATE = '[Pos] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { propertyIDS: number[] }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: Pos[] }) {}
}

export class CreateRequestAction implements Action {
  readonly type = ActionTypes.CREATE_REQUEST;
  constructor(
    public payload: { request: { propertyId: number; name: string } },
  ) {}
}

export class CreateFailureAction implements Action {
  readonly type = ActionTypes.CREATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreateSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
  constructor(public payload: { item: Pos }) {}
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(public payload: { propertyId: number; posId: number }) {}
}

export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(public payload: { posId: number }) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(public payload: { request: { propertyId: number; pos: Pos } }) {}
}

export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { pos: Pos }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | CreateRequestAction
  | CreateFailureAction
  | CreateSuccessAction
  | DeleteRequestAction
  | DeleteFailureAction
  | DeleteSuccessAction
  | UpdateRequestAction
  | UpdateSuccessAction
  | UpdateFailureAction
  | ResetSuccessAction;
