import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PosService } from '../../services';

import * as featureActions from './actions';

@Injectable()
export class PosStoreEffects {
  constructor(
    private dataService: PosService,
    private actions$: Actions,
    private _errorHandler: ErrorHandlerService,
    private _notifications: NotificationService,
    private _translate: TranslateService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) =>
        this.dataService.load(action.payload.propertyIDS).pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this._errorHandler.handle(error);
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        ),
      ),
    ),
  );

  createRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreateRequestAction>(
        featureActions.ActionTypes.CREATE_REQUEST,
      ),
      switchMap(
        ({ payload: { request } }: featureActions.CreateRequestAction) =>
          this.dataService.create(request.propertyId, request.name).pipe(
            map(({ data }: any) => {
              this._notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.create_success',
                  {
                    param: this._translate.instant('pos'),
                  },
                ),
                type: 'success',
              });
              return new featureActions.CreateSuccessAction({
                item: data[0],
              });
            }),
            catchError((error) => {
              this._errorHandler.handle(error);
              return of(new featureActions.CreateFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      switchMap(
        ({
          payload: { propertyId, posId },
        }: featureActions.DeleteRequestAction) =>
          this.dataService.delete(propertyId, posId).pipe(
            map(() => {
              this._notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.delete_success',
                  {
                    param: this._translate.instant('pos'),
                  },
                ),
                type: 'success',
              });

              return new featureActions.DeleteSuccessAction({
                posId,
              });
            }),
            catchError((error) => {
              this._errorHandler.handle(error);
              return of(new featureActions.DeleteFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  updateRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRequestAction>(
        featureActions.ActionTypes.UPDATE_REQUEST,
      ),
      switchMap(
        ({
          payload: {
            request: { propertyId, pos },
          },
        }: featureActions.UpdateRequestAction) =>
          this.dataService.update(propertyId, pos).pipe(
            map((response: IResponseSuccess) => {
              this._notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.update_success',
                  {
                    param: this._translate.instant('pos'),
                  },
                ),
                type: 'success',
              });
              return new featureActions.UpdateSuccessAction({
                pos: response.data[0],
              });
            }),
            catchError((error) => {
              this._errorHandler.handle(error);
              return of(new featureActions.UpdateFailureAction({ error }));
            }),
          ),
      ),
    ),
  );
}
