import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { Pos } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectPosState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('pos');

export const selectAllPosItems: (
  state: object,
) => Pos[] = featureAdapter.getSelectors(selectPosState).selectAll;

export const selectPosById = (id: string) =>
  createSelector(
    selectAllPosItems,
    (allPos: Pos[]) => {
      if (allPos) {
        return allPos.find((p) => p.id === +id);
      } else {
        return null;
      }
    },
  );

export const selectPosError: MemoizedSelector<object, any> = createSelector(
  selectPosState,
  getError,
);

export const selectPosIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  selectPosState,
  getIsLoading,
);
