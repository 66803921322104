import { Actions, ActionTypes } from './actions';
import { featureAdapter, initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.RESET_STATE: {
      return initialState;
    }
    case ActionTypes.LOAD_SUCCESS: {
      return featureAdapter.setAll(action.payload.items, {
        ...state,
        isLoading: false,
        error: null,
      });
    }
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.CREATE_SUCCESS: {
      return featureAdapter.addOne(action.payload.item, {
        ...state,
        isLoading: false,
        error: null,
      });
    }
    case ActionTypes.CREATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.DELETE_SUCCESS: {
      return featureAdapter.removeOne(action.payload.posId, {
        ...state,
        isLoading: false,
        error: null,
      });
    }
    case ActionTypes.DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.UPDATE_SUCCESS: {
      return featureAdapter.updateOne(
        {
          id: action.payload.pos.id,
          changes: action.payload.pos,
        },
        {
          ...state,
          isLoading: false,
          error: null,
        },
      );
    }
    case ActionTypes.UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
